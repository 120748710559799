import { Module, Node } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { IContentBlockViewProps } from './custom-content-block';
import { msdyn365Commerce } from '@msdyn365-commerce/core';

type imageBreakpoint = 'sm' | 'md' | 'lg';

const ContentBlockView: React.FC<IContentBlockViewProps> = props => {
    const {
        contentBlockContainer,
        imageContainer,
        image_sm,
        image_md,
        image_lg,
        breakpoint_sm,
        breakpoint_lg,
        contentBlockAnchorTag,
        imageLink,
        imageAriaLabel
    } = props;

    const imageBreakpointMap: Record<imageBreakpoint, React.ReactNode | null> = {
        sm: image_sm,
        md: image_md,
        lg: image_lg
    };

    const getImageBreakpoint = (): imageBreakpoint => {
        if (msdyn365Commerce.isBrowser) {
            const isLarge = useMediaQuery({
                query: `(min-width: ${breakpoint_lg || 1024}px)`
            });

            const isMed = useMediaQuery({
                query: `(max-width: ${(breakpoint_lg || 1024) - 1}px) and (min-width: ${breakpoint_sm || 480}px)`
            });

            if (isLarge) {
                return 'lg';
            }
            if (isMed) {
                return 'md';
            }
            return 'sm'; // Default to mobile
        }

        // Default breakpoint for SSR or undefined values
        return 'sm';
    };

    const getImage = (breakpoint: imageBreakpoint): React.ReactNode | null => {
        if (imageBreakpointMap[breakpoint]) {
            return imageBreakpointMap[breakpoint];
        } else {
            const keys = Object.keys(imageBreakpointMap) as imageBreakpoint[];
            let idx = keys.findIndex(k => k === breakpoint);

            while (idx < keys.length) {
                const image = imageBreakpointMap[keys[idx]];
                if (image) {
                    return image;
                } else {
                    idx++;
                }
            }
            return null;
        }
    };

    // Check for missing images to avoid rendering issues
    if (!image_sm && !image_md && !image_lg) {
        console.warn('No images provided for ContentBlockView');
        return null;
    }

    return (
        <Module {...contentBlockContainer}>
            {imageLink ? (
                <Node
                    {...contentBlockAnchorTag}
                    href={imageLink}
                    className={contentBlockAnchorTag ? contentBlockAnchorTag.className : ''}
                    aria-label={imageAriaLabel}
                >
                    <Node {...imageContainer}>
                        <img
                            src={getImage(getImageBreakpoint()) as string}
                            alt={imageAriaLabel || 'Content Block Image'}
                            style={{ width: '100%', height: 'auto' }}
                        />
                    </Node>
                </Node>
            ) : (
                <Node {...imageContainer}>
                    <img
                        src={getImage(getImageBreakpoint()) as string}
                        alt={imageAriaLabel || 'Content Block Image'}
                        style={{ width: '100%', height: 'auto' }}
                    />
                </Node>
            )}
        </Module>
    );
};

export default ContentBlockView;
